<template>
  <!--begin::Projects List-->
  <div class="row g-5 gx-xxl-12">
    <div class="col-xxl-12">
      <ProjectsList
          :customer="customer"
          :status="status"
          @filterProjectsTable="filterProjectsListTable"
          widget-classes="card-xxl-stretch mb-5 mb-xxl-12"
      ></ProjectsList>
    </div>
  </div>
  <!--end::Projects List-->
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import ProjectsList from "@/components/widgets/tables/ProjectsList.vue";
import {setCurrentPageBreadcrumbs, setCurrentPageTitle, setCurrentPageToolbarType} from "@/core/helpers/breadcrumb";
import {emitter} from "@/main";

export default defineComponent({
  name: "list",
  components: {
    ProjectsList
  },
  methods: {
    filterProjectsListTable(options) {
      this.status = options.status;
      this.customer = options.customer;
    }
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Project planning and tracking");
      setCurrentPageBreadcrumbs("Project planning and tracking", ["Project management"]);
      setCurrentPageToolbarType("projectsList");
    });
  },
  data() {
    const customer = "All";
    const status = "All";
    return {
      customer,
      status
    }
  }
});
</script>
